const projectsData = [
    {
        id: 1,
        name: "Portfolio Website",
        description: "A personal portfolio designed with React and CSS.",
        thumbnail: "/images/portfolio_web.png",
        techStack: ["React", "CSS", "JavaScript"],
        webLink: ""
    },
    {
        id: 2,
        name: "Stealth Shield -Protect your virtual privacy",
        description: "IVRC 2024 Seed Stage!",
        thumbnail: "/images/IVRC.png",
        techStack: ["Unity", "C#", "Python", "Node.js"],
        webLink: "https://criware.info/ivrc-2024-seed/"
    },
    {
        id: 3,
        name: "The Malleable-Self Experience",
        description: "ACM SIGGRAPH 2024 Audience Choice Award 受賞！",
        thumbnail: "/images/SIGGRAPH.png",
        techStack: ["Unity", "C#", "Arduino"],
        webLink: "https://www.kmd.keio.ac.jp/ja/news/2024/08/%E3%80%90awards%E3%80%91siggraph-2024-emerging-technologies-awards.html"
    },
];

export default projectsData;
